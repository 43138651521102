<template>
  <div class="p-grid p-fluid" v-if="service">
    <TopBar
      :title="`${i18n.$t('View Service')}: ${service.name}`"
      :breadcrumbItems="breadcrumbItems"
    />

    <Steps class="steps" :model="stepItems" :readonly="false" />

    <router-view
      :item="service"
      :employees="employees"
      :schedules="schedules"
      v-if="service"
    ></router-view>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import TopBar from '@/components/Utilities/TopBarBreadCrumb';
import axios from '@/plugins/axios';
import Step2 from '@/components/Service/Step2';
import Step3 from '@/components/Service/Step3';
import { FETCH_AGENCY, GET_AGENCY_MODEL, SET_SERVICE } from '@/store/types';

export default {
  components: {
    TopBar,
    Spinner,
    Step2
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const agency = computed(() => store.getters[GET_AGENCY_MODEL]);
    const router = useRouter();
    const store = useStore();
    const employees = ref([]);
    const service = ref([]);
    const schedules = ref([]);

    const breadcrumbItems = [
      { label: 'Services', to: '/service/list' },
      { label: i18n.$t('View Service'), to: route }
    ];

    const stepItems = [
      {
        label: 'Information',
        name: 'service-view-step1',
        to: `/service/view/step1`
      },
      {
        label: 'Questionnaire',
        name: 'service-view-step2',
        to: `/service/view/step2`
      },
      {
        label: 'Calendar',
        name: 'service-view-step3',
        to: `/service/view/step3`
      }
    ];

    store.dispatch(FETCH_AGENCY);

    initData();

    async function initData() {
      var res = await axios.get(`service?service_id=${route.query.service}`);
      store.commit(SET_SERVICE, res.data.data.items);
      const response = res.data.data.items;
      const list_employees = [];
      const list_schedules = [];
      for (let i = 0; i < response.length; i++) {
        for (let j = 0; j < response[i].employee_id.length; j++) {
          list_employees.push(response[i].employee_id[j]);
        }

        list_schedules.push(response[i].schedule);
      }
      service.value = res.data.data.items[0];
      employees.value = list_employees;
      schedules.value = list_schedules;
    }

    if (!route.query.service) {
      router.push({ name: 'service-list' });
    } else {
      router.push({ name: stepItems[0].name, query: route.query });
    }

    return {
      i18n,
      service,
      agency,
      employees,
      schedules,
      stepItems,
      breadcrumbItems
    };
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding-left: 30px;
  text-align: left;

  width: 100%;
}
.info {
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 0.5rem;
  }
}
.add-btn {
  width: 200px;
  height: 40px;
}
span {
  width: 100%;
}
.service-info {
  max-width: 360px;
}
.drop-down {
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 32px;
  padding: 5px;
}
</style>
<style>
.steps {
  width: 100%;
}
</style>
