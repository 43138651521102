<template>
  <div class="p-col-12 p-mt-4">
    <h4>{{ i18n.$t('service schedule list') }}</h4>

    <DataTable :value="schedules[0]">
      <Column field="true" :header="i18n.$t('Included')">
        <template #body="slotProps">
          <span v-if="slotProps.data.active === true">
            <Button icon="pi pi-check" class="p-button-rounded p-button-text" />
          </span>
          <span v-if="slotProps.data.active === false">
            <Button
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
            />
          </span>
        </template>
      </Column>
      <Column field="label" :header="i18n.$t('Day')"></Column>
      <Column field="sections" :header="i18n.$t('Time')">
        <template #body="slotProps">
          <DataTable :value="slotProps.data.sections[0].workPeriods">
            <Column field="startTime" :header="i18n.$t('Start')"></Column>
            <Column field="endTime" :header="i18n.$t('End')"></Column>
          </DataTable>
        </template>
      </Column>
    </DataTable>
  </div>
</template>


<script>
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    schedules: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    
    return {
      i18n
    };
  }
};
</script>

<style scoped>
h4 {
  line-height: 100%;
}
</style>